import React, { useEffect, useState } from "react"
import { useCheckInStore } from "../stores/useCheckInStore"
import { useTranslation } from "react-i18next"
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    Tooltip,
    CartesianGrid,
    ResponsiveContainer,
} from "recharts"
import { Card, CardContent } from "@/components/ui/card"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog"
import { IconButton } from "@/components/ui/icon-button"
import { XIcon } from "lucide-react"
import { Button } from "components/ui/button"

const Dashboard: React.FC = () => {
    const { t } = useTranslation()
    const { checkIns, fetchCheckIns } = useCheckInStore((state) => ({
        checkIns: state.checkIns,
        fetchCheckIns: state.fetchCheckIns,
    }))

    const [error, setError] = useState<string | null>(null)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedWeightDifferenceRange, setSelectedWeightDifferenceRange] =
        useState<"1week" | "1month" | "6months" | "total">("total")
    const [
        selectedCircumferenceDifferenceRange,
        setSelectedCircumferenceDifferenceRange,
    ] = useState<"1week" | "1month" | "6months" | "total">("total")
    useEffect(() => {
        const loadCheckIns = async () => {
            try {
                await fetchCheckIns()
            } catch (err) {
                const errorMessage =
                    err?.response?.status === 500
                        ? t("server_error") // Custom message for server errors
                        : t("fetch_error") // Default fetch error
                setError(errorMessage)
            }
        }

        loadCheckIns()
    }, [fetchCheckIns, t])

    const latestCheckIn = checkIns.length > 0 ? checkIns[0] : null
    const earliestCheckIn =
        checkIns.length > 0 ? checkIns[checkIns.length - 1] : null
    const totalCheckIns = checkIns.length

    const currentWeight = latestCheckIn ? latestCheckIn.weight : t("na")
    const totalCircumference = latestCheckIn
        ? latestCheckIn.chestMeasurement +
          latestCheckIn.waistMeasurement +
          latestCheckIn.hipMeasurement +
          latestCheckIn.armMeasurement +
          latestCheckIn.legMeasurement
        : t("na")
    // Helper function to calculate date range
    const getCheckInsWithinRange = (
        range: "1week" | "1month" | "6months" | "total"
    ) => {
        const now = new Date()

        const rangeInMs = {
            "1week": 7 * 24 * 60 * 60 * 1000, // 1 week in milliseconds
            "1month": 30 * 24 * 60 * 60 * 1000, // 1 month in milliseconds
            "6months": 6 * 30 * 24 * 60 * 60 * 1000, // 6 months in milliseconds
            total: null,
        }

        const rangeMs = rangeInMs[range]

        return range === "total"
            ? checkIns // If total, return all check-ins
            : checkIns.filter(
                  (checkIn) =>
                      new Date(checkIn.checkInDate).getTime() >=
                      now.getTime() - (rangeMs as number)
              )
    }

    const weightDifferenceCheckInsInRange = getCheckInsWithinRange(
        selectedWeightDifferenceRange
    )
    const earliestWeightDifferenceCheckInInRange =
        weightDifferenceCheckInsInRange.length > 0
            ? weightDifferenceCheckInsInRange[
                  weightDifferenceCheckInsInRange.length - 1
              ]
            : null

    // Calculate the weight difference for the selected range
    const weightDifference =
        latestCheckIn && earliestWeightDifferenceCheckInInRange
            ? (
                  latestCheckIn.weight -
                  earliestWeightDifferenceCheckInInRange.weight
              ).toFixed(2)
            : t("na")

    const weightData = checkIns.map((checkIn) => ({
        date: new Date(checkIn.checkInDate).toLocaleDateString(),
        weight: checkIn.weight,
    }))

    // Assuming weightData is an array of objects with a 'date' property
    const sortedWeightData = weightData
        .slice()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    const circumferenceData = checkIns.map((checkIn) => ({
        date: new Date(checkIn.checkInDate).toLocaleDateString(),
        circumference:
            checkIn.chestMeasurement +
            checkIn.waistMeasurement +
            checkIn.hipMeasurement +
            checkIn.armMeasurement +
            checkIn.legMeasurement,
    }))

    const sortedCircumferenceData = circumferenceData
        .slice()
        .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())

    const circumferenceDifferenceCheckInsInRange = getCheckInsWithinRange(
        selectedCircumferenceDifferenceRange
    )
    const earliestCircumferenceDifferenceCheckInInRange =
        circumferenceDifferenceCheckInsInRange.length > 0
            ? circumferenceDifferenceCheckInsInRange[
                  circumferenceDifferenceCheckInsInRange.length - 1
              ]
            : null
    // Calculate the circumference difference for the selected range
    const circumferenceDifference =
        latestCheckIn && earliestCircumferenceDifferenceCheckInInRange
            ? (
                  latestCheckIn.chestMeasurement +
                  latestCheckIn.waistMeasurement +
                  latestCheckIn.hipMeasurement +
                  latestCheckIn.armMeasurement +
                  latestCheckIn.legMeasurement -
                  (earliestCircumferenceDifferenceCheckInInRange.chestMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.waistMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.hipMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.armMeasurement +
                      earliestCircumferenceDifferenceCheckInInRange.legMeasurement)
              ).toFixed(2)
            : t("na")

    const handleImageClick = (url: string) => {
        setSelectedImage(url)
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedImage(null)
    }

    return (
        <div className="p-4 sm:p-6 w-full">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4">
                {t("dashboard")}
            </h1>

            {/* Display error message if there is an error */}
            {error && <p className="text-red-500 mb-4">{error}</p>}

            <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 sm:gap-6 mt-4">
                {/* Current Weight */}
                <Card className="mt-4">
                    <CardContent>
                        <h2 className="text-md sm:text-lg font-medium">
                            {t("current_weight")}
                        </h2>
                        <p className="text-xl sm:text-2xl font-bold text-primary">
                            {currentWeight} kg
                        </p>
                    </CardContent>
                </Card>

                {/* Total Circumference */}
                <Card className="mt-4">
                    <CardContent>
                        <h2 className="text-md sm:text-lg font-medium">
                            {t("total_circumference")}
                        </h2>
                        <p className="text-xl sm:text-2xl font-bold text-primary">
                            {totalCircumference} cm
                        </p>
                    </CardContent>
                </Card>

                {/* Total Check-Ins */}
                <Card className="mt-4">
                    <CardContent>
                        <h2 className="text-md sm:text-lg font-medium">
                            {t("total_check_ins")}
                        </h2>
                        <p className="text-xl sm:text-2xl font-bold text-primary">
                            {totalCheckIns}
                        </p>
                    </CardContent>
                </Card>

                {/* Weight Difference */}
                <Card className="mt-4">
                    <CardContent>
                        <div className="flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 sm:space-x-4">
                            <h2 className="text-md sm:text-lg font-medium">
                                {t("weight_difference")}
                            </h2>
                            <div className="flex flex-wrap justify-center space-x-2 space-y-2 sm:space-y-0">
                                <Button
                                    variant={
                                        selectedWeightDifferenceRange ===
                                        "1week"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedWeightDifferenceRange(
                                            "1week"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("one_week")}
                                </Button>
                                <Button
                                    variant={
                                        selectedWeightDifferenceRange ===
                                        "1month"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedWeightDifferenceRange(
                                            "1month"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("one_month")}
                                </Button>
                                <Button
                                    variant={
                                        selectedWeightDifferenceRange ===
                                        "6months"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedWeightDifferenceRange(
                                            "6months"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("six_months")}
                                </Button>
                                <Button
                                    variant={
                                        selectedWeightDifferenceRange ===
                                        "total"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedWeightDifferenceRange(
                                            "total"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("total")}
                                </Button>
                            </div>
                        </div>
                        <p className="text-xl sm:text-2xl font-bold text-primary text-center mt-2 sm:mt-4">
                            {weightDifference} kg
                        </p>
                    </CardContent>
                </Card>

                {/* Circumference Difference */}
                <Card className="mt-4">
                    <CardContent>
                        <div className="flex flex-col sm:flex-row items-center justify-between space-y-2 sm:space-y-0 sm:space-x-4">
                            <h2 className="text-md sm:text-lg font-medium">
                                {t("circumference_difference")}
                            </h2>
                            <div className="flex flex-wrap justify-center space-x-2 space-y-2 sm:space-y-0">
                                <Button
                                    variant={
                                        selectedCircumferenceDifferenceRange ===
                                        "1week"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedCircumferenceDifferenceRange(
                                            "1week"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("one_week")}
                                </Button>
                                <Button
                                    variant={
                                        selectedCircumferenceDifferenceRange ===
                                        "1month"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedCircumferenceDifferenceRange(
                                            "1month"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("one_month")}
                                </Button>
                                <Button
                                    variant={
                                        selectedCircumferenceDifferenceRange ===
                                        "6months"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedCircumferenceDifferenceRange(
                                            "6months"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("six_months")}
                                </Button>
                                <Button
                                    variant={
                                        selectedCircumferenceDifferenceRange ===
                                        "total"
                                            ? "default"
                                            : "outline"
                                    }
                                    size="sm"
                                    onClick={() =>
                                        setSelectedCircumferenceDifferenceRange(
                                            "total"
                                        )
                                    }
                                    className="w-full sm:w-auto"
                                >
                                    {t("total")}
                                </Button>
                            </div>
                        </div>
                        <p className="text-xl sm:text-2xl font-bold text-primary text-center mt-2 sm:mt-4">
                            {circumferenceDifference} cm
                        </p>
                    </CardContent>
                </Card>
            </div>

            {/* Comparison Images */}
            {earliestCheckIn && latestCheckIn && (
                <div className="mt-6 space-y-8">
                    <h2 className="text-xl font-semibold mb-4">
                        {t("comparison")}
                    </h2>

                    {/* Earliest Images */}
                    <div className="space-y-4">
                        <h3 className="text-center">{t("earliest_picture")}</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 justify-items-center">
                            {earliestCheckIn.frontPictureUrl && (
                                <img
                                    src={earliestCheckIn.frontPictureUrl}
                                    className="h-48 sm:h-60 object-contain cursor-pointer"
                                    alt="Front"
                                    onClick={() =>
                                        handleImageClick(
                                            earliestCheckIn.frontPictureUrl
                                        )
                                    }
                                />
                            )}
                            {earliestCheckIn.sidePictureUrl && (
                                <img
                                    src={earliestCheckIn.sidePictureUrl}
                                    className="h-48 sm:h-60 object-contain cursor-pointer"
                                    alt="Side"
                                    onClick={() =>
                                        handleImageClick(
                                            earliestCheckIn.sidePictureUrl
                                        )
                                    }
                                />
                            )}
                            {earliestCheckIn.backPictureUrl && (
                                <img
                                    src={earliestCheckIn.backPictureUrl}
                                    className="h-48 sm:h-60 object-contain cursor-pointer"
                                    alt="Back"
                                    onClick={() =>
                                        handleImageClick(
                                            earliestCheckIn.backPictureUrl
                                        )
                                    }
                                />
                            )}
                        </div>
                        <p className="text-center mt-2 text-sm sm:text-base">
                            {new Date(
                                earliestCheckIn.checkInDate
                            ).toLocaleDateString()}
                        </p>
                    </div>

                    {/* Latest Images */}
                    <div className="space-y-4">
                        <h3 className="text-center">{t("latest_picture")}</h3>
                        <div className="grid grid-cols-1 sm:grid-cols-3 gap-2 justify-items-center">
                            {latestCheckIn.frontPictureUrl && (
                                <img
                                    src={latestCheckIn.frontPictureUrl}
                                    className="h-48 sm:h-60 object-contain cursor-pointer"
                                    alt="Front"
                                    onClick={() =>
                                        handleImageClick(
                                            latestCheckIn.frontPictureUrl
                                        )
                                    }
                                />
                            )}
                            {latestCheckIn.sidePictureUrl && (
                                <img
                                    src={latestCheckIn.sidePictureUrl}
                                    className="h-48 sm:h-60 object-contain cursor-pointer"
                                    alt="Side"
                                    onClick={() =>
                                        handleImageClick(
                                            latestCheckIn.sidePictureUrl
                                        )
                                    }
                                />
                            )}
                            {latestCheckIn.backPictureUrl && (
                                <img
                                    src={latestCheckIn.backPictureUrl}
                                    className="h-48 sm:h-60 object-contain cursor-pointer"
                                    alt="Back"
                                    onClick={() =>
                                        handleImageClick(
                                            latestCheckIn.backPictureUrl
                                        )
                                    }
                                />
                            )}
                        </div>
                        <p className="text-center mt-2 text-sm sm:text-base">
                            {new Date(
                                latestCheckIn.checkInDate
                            ).toLocaleDateString()}
                        </p>
                    </div>
                </div>
            )}

            {/* Weight Trend Chart */}
            <div className="mt-6">
                <Card className="mt-4">
                    <CardContent>
                        <h2 className="text-md sm:text-lg font-medium">
                            {t("weight_trend")}
                        </h2>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={sortedWeightData}>
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="date" />
                                <YAxis
                                    domain={["auto", "auto"]}
                                    tickLine={false}
                                    axisLine={false}
                                />
                                <Tooltip />
                                <Line
                                    type="monotone"
                                    dataKey="weight"
                                    stroke="#8884d8"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </div>

            {/* Circumference Trend Chart */}
            <div className="mt-6">
                <Card className="mt-4">
                    <CardContent>
                        <h2 className="text-md sm:text-lg font-medium">
                            {t("circumference_trend")}
                        </h2>
                        <ResponsiveContainer width="100%" height={300}>
                            <LineChart data={sortedCircumferenceData}>
                                <CartesianGrid stroke="#f5f5f5" />
                                <XAxis dataKey="date" />
                                <YAxis
                                    domain={["auto", "auto"]}
                                    tickLine={false}
                                    axisLine={false}
                                />
                                <Tooltip />
                                <Line
                                    type="monotone"
                                    dataKey="circumference"
                                    stroke="#82ca9d"
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </CardContent>
                </Card>
            </div>

            {/* Modal for Image Preview */}
            <Dialog open={modalOpen} onOpenChange={handleModalClose}>
                <DialogContent
                    className="max-w-full sm:max-w-7xl border-0 bg-transparent p-0 flex justify-center items-center"
                    aria-describedby={undefined}
                >
                    <DialogTitle className="sr-only">
                        {t("image_preview")}
                    </DialogTitle>
                    {/* Center the image and close button */}
                    <div className="relative inline-flex flex-col items-center justify-center bg-transparent">
                        <img
                            src={selectedImage || ""}
                            alt="Full Size"
                            className="max-w-[90vw] sm:max-w-[75vw] max-h-[75vh] object-contain"
                        />
                        {/* Position close button near the image */}
                        <IconButton
                            className="absolute top-2 right-2 z-10 bg-gray-800 p-1 rounded-full text-white"
                            onClick={handleModalClose}
                        >
                            <XIcon className="h-6 w-6" />
                        </IconButton>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default Dashboard
