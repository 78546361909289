import React, { useEffect, useState } from "react"
import { useCheckInStore } from "stores/useCheckInStore"
import CheckInForm from "./CheckInForm"
import { useTranslation } from "react-i18next"

// ShadCN Components
import { Button } from "@/components/ui/button"
import { Card, CardContent } from "@/components/ui/card"
import { Dialog, DialogContent, DialogTitle } from "@/components/ui/dialog" // Added DialogTitle
import { IconButton } from "@/components/ui/icon-button"
import { Trash2Icon, PlusIcon, PencilIcon, XIcon } from "lucide-react" // Added PencilIcon for editing

const CheckInList: React.FC = () => {
    const { t } = useTranslation()
    const { checkIns, fetchCheckIns, deleteCheckIn, error, clearError } =
        useCheckInStore((state) => ({
            checkIns: state.checkIns,
            fetchCheckIns: state.fetchCheckIns,
            deleteCheckIn: state.deleteCheckIn,
            error: state.error,
            clearError: state.clearError, // Function to clear error
        }))

    useEffect(() => {
        fetchCheckIns()
    }, [fetchCheckIns])

    const [open, setOpen] = useState<boolean>(false)
    const [isEditing, setIsEditing] = useState<boolean>(false) // Track if editing
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedImage, setSelectedImage] = useState<string | null>(null)
    const [formInitialValues, setFormInitialValues] = useState<any>(null)

    const handleOpen = () => {
        const latestCheckIn = checkIns.length > 0 ? checkIns[0] : null

        setFormInitialValues(
            latestCheckIn
                ? {
                      ...latestCheckIn,
                      frontPhotoUrl: null, // Adjusted field name
                      sidePhotoUrl: null, // Adjusted field name
                      backPhotoUrl: null, // Adjusted field name
                  }
                : {
                      weight: 0,
                      chestMeasurement: 0,
                      waistMeasurement: 0,
                      hipMeasurement: 0,
                      armMeasurement: 0,
                      legMeasurement: 0,
                      frontPhotoUrl: null, // Adjusted field name
                      sidePhotoUrl: null, // Adjusted field name
                      backPhotoUrl: null, // Adjusted field name
                  }
        )
        setIsEditing(false)
        setOpen(true)
    }

    const handleEdit = (checkIn: CheckIn) => {
        setFormInitialValues(checkIn)
        setIsEditing(true)
        setOpen(true)
    }

    const handleClose = () => {
        clearError() // Clear error when closing the form
        setOpen(false)
    }

    const handleImageClick = (url: string) => {
        setSelectedImage(url)
        setModalOpen(true)
    }

    const handleModalClose = () => {
        setModalOpen(false)
        setSelectedImage(null)
    }

    const handleDeleteCheckIn = async (id: string) => {
        try {
            await deleteCheckIn(id)
        } catch {
            // Error handling is done in the store
        }
    }

    return (
        <div className="p-4 sm:p-6 w-full">
            {/* New Check-In Button */}
            <div className="flex justify-end mb-3">
                <Button onClick={handleOpen}>
                    <PlusIcon className="mr-2 h-4 w-4" />
                    {t("new_check_in")}
                </Button>
            </div>

            <CheckInForm
                open={open}
                handleClose={handleClose}
                initialValues={formInitialValues}
                isEditing={isEditing}
            />

            {error && <p className="text-red-500">{t(error)}</p>}

            {/* Check-Ins List */}
            <h1 className="text-xl sm:text-2xl font-semibold mb-4">
                {t("your_check_ins")}
            </h1>

            {checkIns.length > 0 ? (
                <div className="grid grid-cols-1 gap-4 sm:gap-6">
                    {checkIns.map((checkIn) => (
                        <div key={checkIn.id} className="w-full">
                            <Card className="relative shadow-md">
                                <div className="absolute top-2 right-2 flex space-x-2">
                                    {/* Edit Button */}
                                    <IconButton
                                        className="bg-gray-100 hover:bg-gray-200 p-2 rounded-full"
                                        onClick={() => handleEdit(checkIn)}
                                    >
                                        <PencilIcon className="text-black" />
                                    </IconButton>

                                    {/* Delete Button */}
                                    <IconButton
                                        className="bg-gray-100 hover:bg-gray-200 p-2 rounded-full"
                                        onClick={() =>
                                            handleDeleteCheckIn(checkIn.id)
                                        }
                                    >
                                        <Trash2Icon className="text-black" />
                                    </IconButton>
                                </div>

                                {/* Check-In Information */}
                                <CardContent>
                                    <h2 className="text-md sm:text-lg font-bold mb-2">
                                        {new Date(
                                            checkIn.checkInDate
                                        ).toLocaleDateString()}
                                    </h2>

                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-2 sm:gap-4 mb-4">
                                        <p>
                                            {t("weight")}: {checkIn.weight} kg
                                        </p>
                                        <p>
                                            {t("chest")}:{" "}
                                            {checkIn.chestMeasurement} cm
                                        </p>
                                        <p>
                                            {t("waist")}:{" "}
                                            {checkIn.waistMeasurement} cm
                                        </p>
                                        <p>
                                            {t("hip")}: {checkIn.hipMeasurement}{" "}
                                            cm
                                        </p>
                                        <p>
                                            {t("arm")}: {checkIn.armMeasurement}{" "}
                                            cm
                                        </p>
                                        <p>
                                            {t("leg")}: {checkIn.legMeasurement}{" "}
                                            cm
                                        </p>
                                    </div>

                                    {/* Note */}
                                    <div className="border-t pt-2">
                                        {checkIn.note ? (
                                            <p className="text-sm">
                                                {t("note")}: {checkIn.note}
                                            </p>
                                        ) : (
                                            <p className="text-gray-500">
                                                {t("no_note")}
                                            </p>
                                        )}
                                    </div>

                                    {/* Images */}
                                    <div className="border-t pt-2 pb-2">
                                        <div className="flex flex-wrap justify-center sm:space-x-2 mt-3">
                                            {checkIn.frontPictureUrl && (
                                                <img
                                                    className="h-36 w-36 sm:h-40 sm:w-40 object-contain cursor-pointer rounded-md border"
                                                    src={
                                                        checkIn.frontPictureUrl
                                                    } // Adjusted field name
                                                    alt={t("front")}
                                                    onClick={() =>
                                                        handleImageClick(
                                                            checkIn.frontPictureUrl // Adjusted field name
                                                        )
                                                    }
                                                />
                                            )}
                                            {checkIn.sidePictureUrl && (
                                                <img
                                                    className="h-36 w-36 sm:h-40 sm:w-40 object-contain cursor-pointer rounded-md border"
                                                    src={checkIn.sidePictureUrl} // Adjusted field name
                                                    alt={t("side")}
                                                    onClick={() =>
                                                        handleImageClick(
                                                            checkIn.sidePictureUrl // Adjusted field name
                                                        )
                                                    }
                                                />
                                            )}
                                            {checkIn.backPictureUrl && (
                                                <img
                                                    className="h-36 w-36 sm:h-40 sm:w-40 object-contain cursor-pointer rounded-md border"
                                                    src={checkIn.backPictureUrl} // Adjusted field name
                                                    alt={t("back")}
                                                    onClick={() =>
                                                        handleImageClick(
                                                            checkIn.backPictureUrl // Adjusted field name
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>
                                    </div>
                                </CardContent>
                            </Card>
                        </div>
                    ))}
                </div>
            ) : (
                <p className="text-gray-500">{t("no_check_ins_yet")}</p>
            )}

            {/* Modal for Image Preview */}
            <Dialog open={modalOpen} onOpenChange={handleModalClose}>
                <DialogContent
                    className="max-w-full sm:max-w-7xl border-0 bg-transparent p-0 flex justify-center items-center"
                    aria-describedby={undefined}
                >
                    <DialogTitle className="sr-only">
                        {t("image_preview")}
                    </DialogTitle>
                    {/* Accessible DialogTitle */}
                    <div className="relative inline-flex flex-col items-center justify-center bg-transparent">
                        <img
                            src={selectedImage || ""}
                            alt={t("image_preview")}
                            className="max-w-[90vw] sm:max-w-[75vw] max-h-[75vh] object-contain"
                        />
                        <IconButton
                            className="absolute top-2 right-2 z-10 bg-gray-800 p-1 rounded-full text-white sm:hidden"
                            onClick={handleModalClose}
                        >
                            <XIcon className="h-6 w-6" />
                        </IconButton>
                        <IconButton
                            className="absolute top-2 right-2 z-10 bg-gray-800 p-1 rounded-full text-white hidden sm:block"
                            onClick={handleModalClose}
                        >
                            <XIcon className="h-6 w-6" />
                        </IconButton>
                    </div>
                </DialogContent>
            </Dialog>
        </div>
    )
}

export default CheckInList
