import { create } from "zustand"
import {
    login as performLogin,
    logout as performLogout,
    register as performRegister,
    fetchUser as fetchUserFromApi,
    updateUser as updateUserOnApi,
} from "services/authService"

interface User {
    email: string
    age: number
    height: number
    weight: number
}

interface AuthState {
    user: User | null
    setUser: (user: User | null) => void
    login: (email: string, password: string) => Promise<void>
    register: (
        email: string,
        password: string,
        confirmPassword: string
    ) => Promise<void>
    logout: () => void
    fetchUser: () => Promise<void>
    updateUser: (user: User) => Promise<void>
}

export const useAuthStore = create<AuthState>((set) => ({
    user: null, // Initial state is null, will be set after fetching user
    setUser: (user) => set({ user }),

    login: async (email, password) => {
        try {
            await performLogin(email, password)
            // Fetch the user data from the API after login
            const userData = await fetchUserFromApi()
            set({ user: userData })
        } catch (error) {
            throw new Error(
                "Login failed. Please check your credentials and try again."
            )
        }
    },

    register: async (email, password, confirmPassword) => {
        try {
            await performRegister(email, password, confirmPassword)
        } catch (error) {
            throw new Error(
                "Registration failed. Please check the entered details."
            )
        }
    },

    logout: () => {
        performLogout()
        set({ user: null })
    },

    fetchUser: async () => {
        try {
            const user = await fetchUserFromApi()
            set({ user })
        } catch (error) {
            throw new Error(
                "Failed to fetch user data. Please try again later."
            )
        }
    },

    updateUser: async (user) => {
        try {
            await updateUserOnApi(user)
            set({ user })
        } catch (error) {
            throw new Error("Failed to update user data. Please try again.")
        }
    },
}))

export const initializeAuth = async () => {
    const token = localStorage.getItem("user")
    if (token) {
        try {
            await useAuthStore.getState().fetchUser()
        } catch (error) {
            console.error("Failed to initialize auth", error)
        }
    }
}
