const Container: React.FC<{
    children: React.ReactNode
    className?: string
}> = ({ children, className }) => (
    <div className={`max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 ${className}`}>
        {children}
    </div>
)

export { Container }
