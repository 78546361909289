import { create } from "zustand"
import i18n from "utils/i18n" // Your i18n configuration

interface LanguageState {
    language: string
    setLanguage: (language: string) => void
}

export const useLanguageStore = create<LanguageState>((set) => ({
    language: localStorage.getItem("language") || i18n.language || "en", // Default to saved language or 'en'
    setLanguage: (language: string) => {
        i18n.changeLanguage(language) // Change the language using i18next
        localStorage.setItem("language", language) // Save the selected language to localStorage
        set({ language }) // Update Zustand state
    },
}))
