import i18n from "i18next"
import { initReactI18next } from "react-i18next"
import LanguageDetector from "i18next-browser-languagedetector"
import HttpApi from "i18next-http-backend"

// Initialize i18n
i18n.use(HttpApi) // Loads translations from JSON files
    .use(LanguageDetector) // Detects the user's language
    .use(initReactI18next) // Passes the i18n instance to react-i18next
    .init({
        supportedLngs: ["en", "sv"],
        fallbackLng: "en",
        detection: {
            order: ["path", "cookie", "localStorage", "navigator"],
            caches: ["cookie"],
        },
        backend: {
            loadPath: "/locales/{{lng}}.json", // Path to the JSON files
        },
        interpolation: {
            escapeValue: false, // React already protects from XSS
        },
    })

export default i18n
