/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react"
import { Navigate } from "react-router-dom"
import { useAuthStore } from "stores/useAuthStore"

interface ProtectedRouteProps {
    component: React.ComponentType<any> // `any` here allows passing any props to the component
    [key: string]: any // For passing additional props to the component if needed
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
    component: Component,
    ...props
}) => {
    const { user } = useAuthStore()

    if (!user) {
        return <Navigate to="/" />
    }

    return <Component {...props} />
}

export default ProtectedRoute
