import { create } from "zustand"
import {
    getCheckIns as fetchCheckInsFromApi,
    createCheckIn as createCheckInOnApi,
    updateCheckIn as updateCheckInOnApi,
    deleteCheckIn as deleteCheckInOnApi,
    uploadPhotos as uploadPhotosToApi, // Add this service method for photo uploads
} from "services/checkInService"

interface CheckInState {
    checkIns: CheckIn[]
    error: string | null
    setCheckIns: (checkIns: CheckIn[]) => void
    addCheckIn: (checkIn: CheckIn) => void
    updateCheckIn: (
        id: string,
        weight: number,
        chestMeasurement: number,
        waistMeasurement: number,
        hipMeasurement: number,
        armMeasurement: number,
        legMeasurement: number,
        note: string,
        frontPhoto: File | null,
        sidePhoto: File | null,
        backPhoto: File | null,
        checkInDate: Date
    ) => Promise<void>
    deleteCheckIn: (id: string) => Promise<void>
    fetchCheckIns: () => Promise<void>
    createCheckIn: (
        weight: number,
        chestMeasurement: number,
        waistMeasurement: number,
        hipMeasurement: number,
        armMeasurement: number,
        legMeasurement: number,
        note: string,
        frontPhoto: File | null,
        sidePhoto: File | null,
        backPhoto: File | null,
        checkInDate: Date
    ) => Promise<void>
    setError: (message: string | null) => void
    clearError: () => void
}

export const useCheckInStore = create<CheckInState>((set) => ({
    checkIns: [],
    error: null,

    setCheckIns: (checkIns) => {
        const sortedCheckIns = checkIns.sort(
            (a, b) =>
                new Date(b.checkInDate).getTime() -
                new Date(a.checkInDate).getTime()
        )
        set({ checkIns: sortedCheckIns })
    },

    addCheckIn: (checkIn) =>
        set((state) => {
            const updatedCheckIns = [...state.checkIns, checkIn]
            const sortedCheckIns = updatedCheckIns.sort(
                (a, b) =>
                    new Date(b.checkInDate).getTime() -
                    new Date(a.checkInDate).getTime()
            )
            return { checkIns: sortedCheckIns }
        }),

    updateCheckIn: async (
        id,
        weight,
        chestMeasurement,
        waistMeasurement,
        hipMeasurement,
        armMeasurement,
        legMeasurement,
        note,
        frontPhoto,
        sidePhoto,
        backPhoto,
        checkInDate
    ) => {
        try {
            await updateCheckInOnApi(
                id,
                weight,
                chestMeasurement,
                waistMeasurement,
                hipMeasurement,
                armMeasurement,
                legMeasurement,
                note,
                checkInDate
            )

            // Upload photos if provided
            if (frontPhoto || sidePhoto || backPhoto) {
                await uploadPhotosToApi(id, frontPhoto, sidePhoto, backPhoto)
            }

            // Fetch and sort check-ins
            const checkIns = await fetchCheckInsFromApi()
            const sortedCheckIns = checkIns.sort(
                (a, b) =>
                    new Date(b.checkInDate).getTime() -
                    new Date(a.checkInDate).getTime()
            )
            set({ checkIns: sortedCheckIns })
        } catch (error) {
            set({ error: "Failed to update check-in" })
        }
    },

    deleteCheckIn: async (id: string) => {
        try {
            await deleteCheckInOnApi(id)

            // Fetch and sort check-ins
            const checkIns = await fetchCheckInsFromApi()
            const sortedCheckIns = checkIns.sort(
                (a, b) =>
                    new Date(b.checkInDate).getTime() -
                    new Date(a.checkInDate).getTime()
            )
            set({ checkIns: sortedCheckIns })
        } catch (error) {
            set({ error: "Failed to delete check-in" })
        }
    },

    fetchCheckIns: async () => {
        try {
            const checkIns = await fetchCheckInsFromApi()
            set({ checkIns })
        } catch (error) {
            set({ error: "Failed to fetch check-ins" })
        }
    },

    createCheckIn: async (
        weight,
        chestMeasurement,
        waistMeasurement,
        hipMeasurement,
        armMeasurement,
        legMeasurement,
        note,
        frontPhoto,
        sidePhoto,
        backPhoto,
        checkInDate
    ) => {
        try {
            const newCheckIn = await createCheckInOnApi(
                weight,
                chestMeasurement,
                waistMeasurement,
                hipMeasurement,
                armMeasurement,
                legMeasurement,
                note,
                checkInDate
            )

            // Upload photos if provided
            if (frontPhoto || sidePhoto || backPhoto) {
                await uploadPhotosToApi(
                    newCheckIn.checkInId,
                    frontPhoto,
                    sidePhoto,
                    backPhoto
                )
            }

            // Fetch and sort check-ins
            const checkIns = await fetchCheckInsFromApi()
            const sortedCheckIns = checkIns.sort(
                (a, b) =>
                    new Date(b.checkInDate).getTime() -
                    new Date(a.checkInDate).getTime()
            )
            set({ checkIns: sortedCheckIns })
        } catch (error) {
            set({ error: "Failed to create check-in" })
        }
    },

    setError: (message) => set({ error: message }),

    clearError: () => set({ error: null }),
}))
