import React from "react"
import { Routes, Route } from "react-router-dom"
import MainLayout from "layouts/MainLayout"
import Dashboard from "pages/Dashboard"
import Login from "pages/Login"
import Register from "pages/Register"
import Profile from "pages/Profile"
import ProtectedRoute from "components/ProtectedRoute"
import LandingPage from "pages/LandingPage"
import CheckInPage from "pages/CheckInPage"
import SettingsPage from "pages/SettingsPage"

const App: React.FC = () => {
    return (
        <Routes>
            {/* Public routes without MainLayout */}
            <Route path="/" element={<LandingPage />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />

            {/* Routes with MainLayout */}
            <Route element={<MainLayout />}>
                <Route
                    path="/dashboard"
                    element={<ProtectedRoute component={Dashboard} />}
                />
                <Route
                    path="/profile"
                    element={<ProtectedRoute component={Profile} />}
                />
                <Route
                    path="/checkin"
                    element={<ProtectedRoute component={CheckInPage} />}
                />
                <Route
                    path="/settings"
                    element={<ProtectedRoute component={SettingsPage} />}
                />{" "}
                {/* Settings Route */}
            </Route>
        </Routes>
    )
}

export default App
