import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next" // Translation hook
import { Button } from "@/components/ui/button" // ShadCN Button
import { Input } from "@/components/ui/input" // ShadCN Input
import { Loader2 } from "lucide-react"
import { subscribeToNewsletter } from "@/services/newsletterService" // Newsletter service import

const LandingPage: React.FC = () => {
    const { t } = useTranslation() // Use translation hook

    const [email, setEmail] = useState("")
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [error, setError] = useState<string | null>(null)

    const handleNewsletterSignup = async (event: React.FormEvent) => {
        event.preventDefault()
        setLoading(true)
        setError(null)

        try {
            await subscribeToNewsletter(email)
            setSuccess(true)
            setEmail("")
        } catch (error: any) {
            setError(error.message || t("signup_error"))
        } finally {
            setLoading(false)
        }
    }

    return (
        <div className="max-w-4xl mx-auto text-center mt-20 space-y-8 px-6">
            {/* Heading and Description */}
            <h1 className="text-4xl font-extrabold mb-4 text-gray-900">
                Strengthara: {t("coming_soon_title")}
            </h1>
            <p className="text-lg mb-6 text-gray-700">
                {t("coming_soon_subtitle", {
                    app_name: "Strengthara",
                    description: "Your personal fitness companion",
                })}
            </p>

            {/* Call to Action Buttons */}
            <div className="flex justify-center space-x-4">
                <Button size="lg" asChild>
                    <Link to="/login">{t("login")}</Link>
                </Button>
                <Button size="lg" variant="outline" asChild>
                    <Link to="/register">{t("register")}</Link>
                </Button>
            </div>

            {/* Features Section */}
            <div className="mt-12">
                <h2 className="text-2xl font-bold text-gray-800 mb-6">
                    {t("features_title")}
                </h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 gap-6">
                    {/* Feature 1 */}
                    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">
                            {t("feature_1_title")}
                        </h3>
                        <p className="text-gray-600">
                            {t("feature_1_description")}
                        </p>
                    </div>

                    {/* Feature 2 */}
                    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">
                            {t("feature_2_title")}
                        </h3>
                        <p className="text-gray-600">
                            {t("feature_2_description")}
                        </p>
                    </div>

                    {/* Feature 3 */}
                    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">
                            {t("feature_3_title")}
                        </h3>
                        <p className="text-gray-600">
                            {t("feature_3_description")}
                        </p>
                    </div>

                    {/* Feature 4 */}
                    <div className="bg-gray-100 p-6 rounded-lg shadow-md">
                        <h3 className="text-xl font-semibold mb-2 text-gray-800">
                            {t("feature_4_title")}
                        </h3>
                        <p className="text-gray-600">
                            {t("feature_4_description")}
                        </p>
                    </div>
                </div>
            </div>

            {/* Newsletter Sign-Up */}
            <div className="mt-12">
                <h2 className="text-xl font-bold text-gray-800 mb-4">
                    {t("newsletter_title")}
                </h2>
                <p className="text-gray-600 mb-6">
                    {t("newsletter_description", { app_name: "Strengthara" })}
                </p>
                <form onSubmit={handleNewsletterSignup} className="space-y-4">
                    <div className="flex justify-center space-x-2">
                        <Input
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder={t("email_placeholder")}
                            className="w-64"
                        />
                        <Button type="submit" disabled={loading}>
                            {loading ? (
                                <Loader2 className="h-5 w-5 animate-spin" />
                            ) : (
                                t("subscribe")
                            )}
                        </Button>
                    </div>
                    {error && <p className="text-red-500">{error}</p>}
                    {success && (
                        <p className="text-green-500">{t("signup_success")}</p>
                    )}
                </form>
            </div>

            {/* Footer */}
            <footer className="mt-16 text-sm text-gray-500">
                <p>{t("footer_text", { app_name: "Strengthara" })}</p>
            </footer>
        </div>
    )
}

export default LandingPage
