import React, { useState } from "react"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next" // Translation hook
import { Input } from "@/components/ui/input" // ShadCN Input
import { Button } from "@/components/ui/button"

const Register: React.FC = () => {
    const { t } = useTranslation() // Use translation hook
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [confirmPassword, setConfirmPassword] = useState("")
    const [loading] = useState(false)
    const [errorMessage, setErrorMessage] = useState<string | null>(null) // Error handling

    const handleRegister = async () => {
        setErrorMessage(t("registration_coming_soon"))
    }

    return (
        <div className="flex items-center justify-center h-screen bg-gray-100">
            <div className="bg-white p-8 shadow-lg rounded-lg max-w-md w-full">
                <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
                    {t("register")}
                </h1>
                <p className="text-center text-lg text-gray-600 mb-6">
                    {t("registration_coming_soon")}
                </p>

                {errorMessage && (
                    <p className="text-red-500 text-center mb-4">
                        {errorMessage}
                    </p>
                )}

                <div className="space-y-6">
                    <div>
                        <label
                            htmlFor="email"
                            className="block text-sm font-medium"
                        >
                            {t("email")}
                        </label>
                        <Input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            className="w-full mt-2"
                            placeholder={t("email") as string}
                            disabled={loading}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="password"
                            className="block text-sm font-medium"
                        >
                            {t("password")}
                        </label>
                        <Input
                            id="password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            className="w-full mt-2"
                            placeholder={t("password") as string}
                            disabled={loading}
                        />
                    </div>
                    <div>
                        <label
                            htmlFor="confirm-password"
                            className="block text-sm font-medium"
                        >
                            {t("confirm_password")}
                        </label>
                        <Input
                            id="confirm-password"
                            type="password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                            className="w-full mt-2"
                            placeholder={t("confirm_password") as string}
                            disabled={loading}
                        />
                    </div>

                    <Button
                        className="relative w-full flex justify-center items-center text-white"
                        onClick={handleRegister}
                        disabled
                    >
                        {t("register")}
                    </Button>
                </div>

                {/* Back to Home Button */}
                <div className="text-center mt-6">
                    <Link to="/" className=" hover:underline">
                        {t("back_to_home")}
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Register
