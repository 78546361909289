/* eslint-disable @typescript-eslint/no-explicit-any */
import axios from "axios"
import apiClient from "utils/api" // Import the configured Axios instance

interface AuthResponse {
    token: string
}

// Register a new user
export const register = async (
    email: string,
    password: string,
    confirmPassword: string
) => {
    try {
        await apiClient.post("/auth/register", {
            email,
            password,
            confirmPassword,
        })
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Registration failed")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Login a user and store the JWT token
export const login = async (
    email: string,
    password: string
): Promise<AuthResponse> => {
    try {
        const response = await apiClient.post<AuthResponse>("/auth/login", {
            email,
            password,
        })
        if (response.data.token) {
            localStorage.setItem("user", JSON.stringify(response.data))
        }
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Login failed")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Logout a user
export const logout = () => {
    localStorage.removeItem("user")
}

// Fetch user data
export const fetchUser = async () => {
    try {
        const response = await apiClient.get("/user")
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(error.response?.data || "Failed to fetch user data")
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}

// Update user information
export const updateUser = async (updatedInfo: any) => {
    try {
        const response = await apiClient.put("/user", updatedInfo)
        return response.data
    } catch (error) {
        if (axios.isAxiosError(error)) {
            throw new Error(
                error.response?.data || "Failed to update user information"
            )
        } else {
            throw new Error("An unexpected error occurred")
        }
    }
}
