import React from "react"
import { useThemeStore } from "stores/useThemeStore"
import { useTranslation } from "react-i18next" // Import for translations
import { Switch } from "@/components/ui/switch" // ShadCN Switch

const SettingsPage: React.FC = () => {
    const { theme, toggleTheme } = useThemeStore() // Access the theme store
    const { t } = useTranslation() // Use translation

    return (
        <div className="p-4 sm:p-6 w-full max-w-lg mx-auto">
            <h1 className="text-2xl sm:text-3xl font-bold mb-6 text-center text-gray-900 dark:text-gray-100">
                {t("settings")}
            </h1>

            {/* Dark Mode Section */}
            <div className="p-4 sm:p-6 rounded-lg shadow-lg bg-white dark:bg-gray-800">
                <h2 className="text-lg sm:text-xl font-bold mb-4 text-gray-900 dark:text-gray-100">
                    {t("theme")}
                </h2>
                <div className="flex flex-col sm:flex-row items-center justify-between mb-4">
                    <div className="text-center sm:text-left mb-4 sm:mb-0">
                        <h3 className="text-base sm:text-lg font-medium text-gray-900 dark:text-gray-100">
                            {t("dark_mode")}
                        </h3>
                        <p className="text-sm mt-2 text-gray-700 dark:text-gray-300">
                            {t("dark_mode_description")}
                        </p>
                    </div>
                    <Switch
                        checked={theme === "dark"}
                        onCheckedChange={toggleTheme}
                        className="ml-0 sm:ml-4"
                    />
                </div>
            </div>
        </div>
    )
}

export default SettingsPage
