import React, { useEffect, useState } from "react"
import { useAuthStore } from "stores/useAuthStore"
import { useTranslation } from "react-i18next"
import { Button } from "@/components/ui/button" // ShadCN Button
import { Input } from "@/components/ui/input" // ShadCN Input
import { Loader2 } from "lucide-react" // Loader icon
import { Card, CardContent } from "@/components/ui/card"

const Profile: React.FC = () => {
    const { t } = useTranslation() // Use translation hook
    const { user, fetchUser, updateUser } = useAuthStore()
    const [formData, setFormData] = useState<any>(null)
    const [loading, setLoading] = useState<boolean>(true)
    const [error, setError] = useState<string | null>(null)
    const [updating, setUpdating] = useState<boolean>(false)

    useEffect(() => {
        const loadUserData = async () => {
            try {
                await fetchUser()
                setLoading(false)
            } catch (err) {
                setError(t("fetch_error"))
                setLoading(false)
            }
        }

        loadUserData()
    }, [fetchUser, t])

    useEffect(() => {
        if (user) {
            setFormData(user)
        }
    }, [user])

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setFormData({ ...formData, [e.target.name]: e.target.value })
    }

    const handleSubmit = async (e: React.FormEvent) => {
        e.preventDefault()
        setUpdating(true)
        setError(null)
        try {
            await updateUser(formData)
        } catch (err) {
            setError(t("update_error"))
        } finally {
            setUpdating(false)
        }
    }

    if (loading) {
        return (
            <div className="flex justify-center items-center h-screen">
                <Loader2 className="h-12 w-12 animate-spin" />
            </div>
        )
    }

    return (
        <div className="p-4 sm:p-6 max-w-lg mx-auto">
            <h1 className="text-2xl sm:text-3xl font-bold mb-4 sm:mb-6 text-center">
                {t("profile")}
            </h1>
            {error && <p className="text-red-500 mb-4">{error}</p>}
            {user && (
                <Card className="w-full">
                    <CardContent>
                        <form
                            onSubmit={handleSubmit}
                            className="space-y-4 sm:space-y-6"
                        >
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium"
                                >
                                    {t("email")}
                                </label>
                                <Input
                                    id="email"
                                    name="email"
                                    type="email"
                                    value={formData?.email || ""}
                                    onChange={handleChange}
                                    className="w-full mt-1"
                                    disabled
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="age"
                                    className="block text-sm font-medium"
                                >
                                    {t("age")}
                                </label>
                                <Input
                                    id="age"
                                    name="age"
                                    type="number"
                                    value={formData?.age || ""}
                                    onChange={handleChange}
                                    className="w-full mt-1"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="height"
                                    className="block text-sm font-medium"
                                >
                                    {t("height")}
                                </label>
                                <Input
                                    id="height"
                                    name="height"
                                    type="number"
                                    value={formData?.height || ""}
                                    onChange={handleChange}
                                    className="w-full mt-1"
                                />
                            </div>

                            <div>
                                <label
                                    htmlFor="weight"
                                    className="block text-sm font-medium"
                                >
                                    {t("weight")}
                                </label>
                                <Input
                                    id="weight"
                                    name="weight"
                                    type="number"
                                    value={formData?.weight || ""}
                                    onChange={handleChange}
                                    className="w-full mt-1"
                                />
                            </div>

                            <Button
                                type="submit"
                                className="w-full mt-4"
                                disabled={updating}
                            >
                                {updating ? (
                                    <Loader2 className="h-5 w-5 animate-spin mr-2 inline-block" />
                                ) : (
                                    t("update")
                                )}
                            </Button>
                        </form>
                    </CardContent>
                </Card>
            )}
        </div>
    )
}

export default Profile
