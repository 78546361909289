import axios from "axios"

const API_URL = import.meta.env.VITE_API_URL // Base URL for your API

// Create an Axios instance
const apiClient = axios.create({
    baseURL: API_URL,
})

// Attach the interceptor for requests
apiClient.interceptors.request.use(
    (config) => {
        const token = getAuthToken()
        if (token) {
            config.headers.Authorization = `Bearer ${token}`
        }
        return config
    },
    (error) => Promise.reject(error)
)

// Attach the interceptor for responses
apiClient.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config

        // If the error is due to token expiration
        if (error.response.status === 401 && !originalRequest._retry) {
            originalRequest._retry = true

            try {
                const token = getAuthToken()
                // Refresh the token
                const refreshToken = await refreshAuthToken(token) // Assume this is a function to refresh the token
                localStorage.setItem("user", JSON.stringify(refreshToken))

                // Set the new token in the request header and retry the original request
                apiClient.defaults.headers.Authorization = `Bearer ${refreshToken.token}`
                return apiClient(originalRequest)
            } catch (err) {
                console.error("Token refresh failed", err)
                return Promise.reject(err)
            }
        }

        return Promise.reject(error)
    }
)

const getAuthToken = () => {
    const user = localStorage.getItem("user")
    return user ? JSON.parse(user).token : null
}

const refreshAuthToken = async (token: string) => {
    // Implement the token refresh logic here
    const response = await axios.post(
        `${API_URL}/auth/RefreshToken`,
        { refreshToken: token },
        {
            headers: { Authorization: `Bearer ${getAuthToken()}` },
        }
    )
    return response.data
}

export default apiClient
