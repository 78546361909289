import React, { useEffect, useState } from "react"
import { useCheckInStore } from "stores/useCheckInStore"
import { useTranslation } from "react-i18next"

// ShadCN and Radix UI Components
import { Button } from "@/components/ui/button"
import { Input } from "@/components/ui/input"
import {
    Dialog,
    DialogContent,
    DialogHeader,
    DialogTitle,
} from "@/components/ui/dialog"
import { Label } from "@/components/ui/label"
import { Separator } from "@/components/ui/separator"
import { Loader2 } from "lucide-react" // Loader icon
import { Textarea } from "@/components/ui/textarea"
import { Calendar } from "@/components/ui/calendar"
import {
    Popover,
    PopoverContent,
    PopoverTrigger,
} from "@/components/ui/popover"

interface CheckInFormProps {
    open: boolean
    handleClose: () => void
    initialValues?: CheckIn | null
    isEditing?: boolean
}

const CheckInForm: React.FC<CheckInFormProps> = ({
    open,
    handleClose,
    initialValues,
    isEditing = false,
}) => {
    const { createCheckIn, updateCheckIn } = useCheckInStore((state) => ({
        createCheckIn: state.createCheckIn,
        updateCheckIn: state.updateCheckIn,
    }))

    const { t } = useTranslation()

    const [weight, setWeight] = useState<number | "">(0)
    const [chestMeasurement, setChestMeasurement] = useState<number | "">(0)
    const [waistMeasurement, setWaistMeasurement] = useState<number | "">(0)
    const [hipMeasurement, setHipMeasurement] = useState<number | "">(0)
    const [armMeasurement, setArmMeasurement] = useState<number | "">(0)
    const [legMeasurement, setLegMeasurement] = useState<number | "">(0)
    const [frontPhoto, setFrontPhoto] = useState<File | null>(null)
    const [sidePhoto, setSidePhoto] = useState<File | null>(null)
    const [backPhoto, setBackPhoto] = useState<File | null>(null)
    const [note, setNote] = useState<string>("")
    const [checkInDate, setCheckInDate] = useState<Date>(new Date())

    const [frontPhotoPreview, setFrontPhotoPreview] = useState<string | null>(
        null
    )
    const [sidePhotoPreview, setSidePhotoPreview] = useState<string | null>(
        null
    )
    const [backPhotoPreview, setBackPhotoPreview] = useState<string | null>(
        null
    )

    const [loading, setLoading] = useState<boolean>(false)
    const [imageLoading, setImageLoading] = useState<string | null>(null) // Track which image is loading
    const [errorMessage, setErrorMessage] = useState<string | null>(null) // Error message state

    // Keep a copy of initial values to reset the form on cancel
    const [initialFormValues, setInitialFormValues] = useState({
        weight: 0,
        chestMeasurement: 0,
        waistMeasurement: 0,
        hipMeasurement: 0,
        armMeasurement: 0,
        legMeasurement: 0,
        frontPhotoUrl: null,
        sidePhotoUrl: null,
        backPhotoUrl: null,
        note: "",
        checkInDate: new Date(),
    })

    useEffect(() => {
        if (initialValues) {
            setInitialFormValues({
                weight: initialValues.weight || 0,
                chestMeasurement: initialValues.chestMeasurement || 0,
                waistMeasurement: initialValues.waistMeasurement || 0,
                hipMeasurement: initialValues.hipMeasurement || 0,
                armMeasurement: initialValues.armMeasurement || 0,
                legMeasurement: initialValues.legMeasurement || 0,
                frontPhotoUrl: initialValues.frontPictureUrl || null,
                sidePhotoUrl: initialValues.sidePictureUrl || null,
                backPhotoUrl: initialValues.backPictureUrl || null,
                note: "",
                checkInDate: new Date(),
            })

            setWeight(initialValues.weight || "")
            setChestMeasurement(initialValues.chestMeasurement || "")
            setWaistMeasurement(initialValues.waistMeasurement || "")
            setHipMeasurement(initialValues.hipMeasurement || "")
            setArmMeasurement(initialValues.armMeasurement || "")
            setLegMeasurement(initialValues.legMeasurement || "")
        }

        if (isEditing && initialValues) {
            setFrontPhotoPreview(initialValues.frontPictureUrl || null)
            setSidePhotoPreview(initialValues.sidePictureUrl || null)
            setBackPhotoPreview(initialValues.backPictureUrl || null)
            setNote(initialValues.note || "") // Set note value
            // Set check-in date when editing
            setCheckInDate(
                initialValues.checkInDate
                    ? new Date(initialValues.checkInDate)
                    : new Date()
            )
        } else {
            setFrontPhotoPreview(null)
            setSidePhotoPreview(null)
            setBackPhotoPreview(null)
            setNote("")
            setCheckInDate(new Date())
        }
    }, [initialValues, isEditing])

    // Handle file change to set the preview
    const handleFileChange = (
        event: React.ChangeEvent<HTMLInputElement>,
        setPhoto: React.Dispatch<React.SetStateAction<File | null>>,
        setPreview: React.Dispatch<React.SetStateAction<string | null>>,
        imageName: string
    ) => {
        if (event.target.files && event.target.files[0]) {
            const file = event.target.files[0]
            setPhoto(file)

            const reader = new FileReader()
            setImageLoading(imageName) // Start loader for specific image
            reader.onloadend = () => {
                setPreview(reader.result as string) // Set preview for the image
                setImageLoading(null) // Stop loader once image is ready
            }
            reader.readAsDataURL(file)
        }
    }

    // Reset the form and preview URLs to the initial values
    const resetFormToInitialValues = () => {
        setWeight(initialFormValues.weight)
        setChestMeasurement(initialFormValues.chestMeasurement)
        setWaistMeasurement(initialFormValues.waistMeasurement)
        setHipMeasurement(initialFormValues.hipMeasurement)
        setArmMeasurement(initialFormValues.armMeasurement)
        setLegMeasurement(initialFormValues.legMeasurement)
        setFrontPhotoPreview(initialFormValues.frontPhotoUrl)
        setSidePhotoPreview(initialFormValues.sidePhotoUrl)
        setBackPhotoPreview(initialFormValues.backPhotoUrl)
        setNote(initialFormValues.note)
        setErrorMessage(null) // Clear any error message
    }

    const handleCloseAndReset = () => {
        resetFormToInitialValues() // Reset form to initial values
        handleClose() // Close the modal
    }

    // Set check-in date to UTC midnight (to prevent timezone issues)
    const utcCheckInDate = new Date(
        Date.UTC(
            checkInDate.getFullYear(),
            checkInDate.getMonth(),
            checkInDate.getDate()
        )
    )

    const handleSubmit = async (event: React.FormEvent) => {
        event.preventDefault()
        setLoading(true)
        setErrorMessage(null)

        const numericWeight =
            typeof weight === "number" ? weight : parseFloat(weight as string)
        const numericChestMeasurement =
            typeof chestMeasurement === "number"
                ? chestMeasurement
                : parseFloat(chestMeasurement as string)
        const numericWaistMeasurement =
            typeof waistMeasurement === "number"
                ? waistMeasurement
                : parseFloat(waistMeasurement as string)
        const numericHipMeasurement =
            typeof hipMeasurement === "number"
                ? hipMeasurement
                : parseFloat(hipMeasurement as string)
        const numericArmMeasurement =
            typeof armMeasurement === "number"
                ? armMeasurement
                : parseFloat(armMeasurement as string)
        const numericLegMeasurement =
            typeof legMeasurement === "number"
                ? legMeasurement
                : parseFloat(legMeasurement as string)

        try {
            if (isEditing && initialValues) {
                await updateCheckIn(
                    initialValues.id, // Pass the ID for updating
                    numericWeight,
                    numericChestMeasurement,
                    numericWaistMeasurement,
                    numericHipMeasurement,
                    numericArmMeasurement,
                    numericLegMeasurement,
                    note,
                    frontPhoto,
                    sidePhoto,
                    backPhoto,
                    utcCheckInDate
                )
            } else {
                await createCheckIn(
                    numericWeight,
                    numericChestMeasurement,
                    numericWaistMeasurement,
                    numericHipMeasurement,
                    numericArmMeasurement,
                    numericLegMeasurement,
                    note,
                    frontPhoto,
                    sidePhoto,
                    backPhoto,
                    utcCheckInDate
                )
            }
            handleCloseAndReset()
        } catch (error: any) {
            setErrorMessage(error.message || t("submit_error"))
        } finally {
            setLoading(false)
        }
    }

    return (
        <Dialog
            open={open}
            onOpenChange={(isOpen) => !isOpen && handleCloseAndReset()}
        >
            <DialogContent
                className="max-h-screen overflow-y-auto p-4 max-w-full sm:max-w-lg mx-auto bg-background text-foreground"
                aria-describedby={undefined}
            >
                <DialogHeader>
                    <DialogTitle>
                        {isEditing ? t("editCheckIn") : t("addCheckIn")}
                    </DialogTitle>
                </DialogHeader>

                {errorMessage && (
                    <p className="text-red-500 mb-4">{errorMessage}</p>
                )}

                <form onSubmit={handleSubmit} className="space-y-4">
                    <div>
                        <Label htmlFor="checkInDate">{t("checkin_date")}</Label>
                        <Popover>
                            <PopoverTrigger asChild>
                                <Button
                                    variant="outline"
                                    className="w-full justify-start text-left font-normal bg-input text-foreground"
                                >
                                    {checkInDate
                                        ? checkInDate.toLocaleDateString()
                                        : t("select_date")}
                                </Button>
                            </PopoverTrigger>
                            <PopoverContent className="w-auto p-0">
                                <Calendar
                                    mode="single"
                                    selected={checkInDate}
                                    onSelect={(date) => {
                                        // Ensure the date is set to local midnight to avoid timezone shift
                                        setCheckInDate(
                                            new Date(
                                                date.getFullYear(),
                                                date.getMonth(),
                                                date.getDate()
                                            )
                                        )
                                    }}
                                    initialFocus
                                />
                            </PopoverContent>
                        </Popover>
                    </div>

                    <div>
                        <Label htmlFor="weight">{t("weight")}</Label>
                        <Input
                            id="weight"
                            type="number"
                            value={weight === "" ? "" : weight}
                            onChange={(e) =>
                                setWeight(
                                    e.target.value === ""
                                        ? ""
                                        : parseFloat(e.target.value)
                                )
                            }
                            className="bg-input text-foreground"
                        />
                    </div>

                    <div>
                        <Label htmlFor="chestMeasurement">
                            {t("chestMeasurement")}
                        </Label>
                        <Input
                            id="chestMeasurement"
                            type="number"
                            value={
                                chestMeasurement === "" ? "" : chestMeasurement
                            }
                            onChange={(e) =>
                                setChestMeasurement(
                                    e.target.value === ""
                                        ? ""
                                        : parseFloat(e.target.value)
                                )
                            }
                            className="bg-input text-foreground"
                        />
                    </div>

                    <div>
                        <Label htmlFor="waistMeasurement">
                            {t("waistMeasurement")}
                        </Label>
                        <Input
                            id="waistMeasurement"
                            type="number"
                            value={
                                waistMeasurement === "" ? "" : waistMeasurement
                            }
                            onChange={(e) =>
                                setWaistMeasurement(
                                    e.target.value === ""
                                        ? ""
                                        : parseFloat(e.target.value)
                                )
                            }
                            className="bg-input text-foreground"
                        />
                    </div>

                    <div>
                        <Label htmlFor="hipMeasurement">
                            {t("hipMeasurement")}
                        </Label>
                        <Input
                            id="hipMeasurement"
                            type="number"
                            value={hipMeasurement === "" ? "" : hipMeasurement}
                            onChange={(e) =>
                                setHipMeasurement(
                                    e.target.value === ""
                                        ? ""
                                        : parseFloat(e.target.value)
                                )
                            }
                            className="bg-input text-foreground"
                        />
                    </div>

                    <div>
                        <Label htmlFor="armMeasurement">
                            {t("armMeasurement")}
                        </Label>
                        <Input
                            id="armMeasurement"
                            type="number"
                            value={armMeasurement === "" ? "" : armMeasurement}
                            onChange={(e) =>
                                setArmMeasurement(
                                    e.target.value === ""
                                        ? ""
                                        : parseFloat(e.target.value)
                                )
                            }
                            className="bg-input text-foreground"
                        />
                    </div>

                    <div>
                        <Label htmlFor="legMeasurement">
                            {t("legMeasurement")}
                        </Label>
                        <Input
                            id="legMeasurement"
                            type="number"
                            value={legMeasurement === "" ? "" : legMeasurement}
                            onChange={(e) =>
                                setLegMeasurement(
                                    e.target.value === ""
                                        ? ""
                                        : parseFloat(e.target.value)
                                )
                            }
                            className="bg-input text-foreground"
                        />
                    </div>

                    <div>
                        <Label htmlFor="note">{t("note")}</Label>
                        <Textarea
                            id="note"
                            value={note}
                            onChange={(e) => setNote(e.target.value)}
                            className="bg-input text-foreground"
                        />
                    </div>

                    <Separator />

                    <div className="grid grid-cols-1 sm:grid-cols-3 gap-4">
                        {/* Front photo */}
                        <div>
                            <Button asChild>
                                <label>
                                    {t("frontPhoto")}
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) =>
                                            handleFileChange(
                                                e,
                                                setFrontPhoto,
                                                setFrontPhotoPreview,
                                                "frontPhoto"
                                            )
                                        }
                                    />
                                </label>
                            </Button>
                            {imageLoading === "frontPhoto" ? (
                                <Loader2 className="animate-spin mt-2 h-6 w-6" />
                            ) : (
                                frontPhotoPreview && (
                                    <img
                                        src={frontPhotoPreview}
                                        alt="Front Preview"
                                        className="mt-2 h-24 w-24 object-contain"
                                    />
                                )
                            )}
                        </div>

                        {/* Side photo */}
                        <div>
                            <Button asChild>
                                <label>
                                    {t("sidePhoto")}
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) =>
                                            handleFileChange(
                                                e,
                                                setSidePhoto,
                                                setSidePhotoPreview,
                                                "sidePhoto"
                                            )
                                        }
                                    />
                                </label>
                            </Button>
                            {imageLoading === "sidePhoto" ? (
                                <Loader2 className="animate-spin mt-2 h-6 w-6" />
                            ) : (
                                sidePhotoPreview && (
                                    <img
                                        src={sidePhotoPreview}
                                        alt="Side Preview"
                                        className="mt-2 h-24 w-24 object-contain"
                                    />
                                )
                            )}
                        </div>

                        {/* Back photo */}
                        <div>
                            <Button asChild>
                                <label>
                                    {t("backPhoto")}
                                    <input
                                        type="file"
                                        hidden
                                        onChange={(e) =>
                                            handleFileChange(
                                                e,
                                                setBackPhoto,
                                                setBackPhotoPreview,
                                                "backPhoto"
                                            )
                                        }
                                    />
                                </label>
                            </Button>
                            {imageLoading === "backPhoto" ? (
                                <Loader2 className="animate-spin mt-2 h-6 w-6" />
                            ) : (
                                backPhotoPreview && (
                                    <img
                                        src={backPhotoPreview}
                                        alt="Back Preview"
                                        className="mt-2 h-24 w-24 object-contain"
                                    />
                                )
                            )}
                        </div>
                    </div>

                    <div className="flex justify-end space-x-2">
                        <Button
                            type="submit"
                            variant="default"
                            disabled={loading}
                            className="bg-primary text-primary-foreground"
                        >
                            {loading ? (
                                <Loader2 className="animate-spin h-5 w-5" />
                            ) : isEditing ? (
                                t("saveChanges")
                            ) : (
                                t("submit")
                            )}
                        </Button>
                        <Button
                            variant="outline"
                            onClick={handleCloseAndReset}
                            type="button"
                            className="text-foreground"
                        >
                            {t("cancel")}
                        </Button>
                    </div>
                </form>
            </DialogContent>
        </Dialog>
    )
}

export default CheckInForm
